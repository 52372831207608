import React, { useState, useEffect, useCallback, useRef } from "react";
import "../styles.css";
import logo from "../assets/logo.png";
import { Button } from "react-day-picker";
import ChatScreen from "./ChatScreen";
import { useNavigate } from "react-router-dom";
import Dropdownllm from "../components/Llm";
import InputText from "../components/SendMessage";
import { Message } from "../components/SendMessage";
import { ChatHistory } from "../components/SendMessage";
import { InputTextProps } from "../components/SendMessage";
import settingImage from "src/assets/settings.png";
import Sidebar, { Image } from "../components/SideBar";
import SuggestedTopics from "./SuggestedTopics";
import * as Dialog from "@radix-ui/react-dialog";
import crossicon from "src/assets/cross-2.svg";
import "src/modal.css";
import SettingsPanel from "src/components/SettingsPanel";
import NavigationBar from "./Navigationbar";

const WelcomeSection: React.FC<InputTextProps> = ({
  selectedCreator,

  selectedModel,
  setSelectedModel,
}) => {
  //this will save the chats to local/ using cache
  const [chats, setChats] = useState<ChatHistory[]>(() => {
    const savedChats = sessionStorage.getItem("chats");
    return savedChats ? JSON.parse(savedChats) : [];
  });
  useEffect(() => {
    sessionStorage.setItem("chats", JSON.stringify(chats));
  }, [chats]);

  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const updateChatMessages = (chatId: number, messages: Message[]) => {
    const updatedChats = chats.map((chat) => {
      if (chat.id === chatId) {
        return { ...chat, messages };
      }
      return chat;
    });
    setChats(updatedChats);
  };

  //automatic add a chat if chat array is empty
  const handleNewChat = useCallback(() => {
    // Find the maximum existing ID
    const maxId = chats.reduce((max, chat) => Math.max(max, chat.id), -0);
    const newChat: ChatHistory = {
      // thread_id: 263,
      id: maxId + 1,
      title: `Chat ${maxId + 1}`,
      messages: [],
    };
    setChats((prevChats) => [...prevChats, newChat]);
    setSelectedChatId(newChat.id);
  }, [chats.length]);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/chat", { state: { fromWelcomeScreen: true } });
  };
  // const [inputText, setInputText] = useState("");
  const [inputText, setInputText] = useState<string>(() => {
    const savedInputText = sessionStorage.getItem("inputText");
    return savedInputText || "";
  });

  useEffect(() => {
    sessionStorage.setItem("inputText", inputText);
  }, [inputText]);

  const [isLoading, setIsLoading] = useState(false);

  // const handleSettingsChange = (newSettings: any) => {
  //   setSettings(newSettings);
  // };

  //for advance settings
  const [systemInstructions, setSystemInstructions] = useState<string>(() => {
    const savedSystemInstructions =
      sessionStorage.getItem("systemInstructions");
    return savedSystemInstructions || "";
  });
  const [temperature, setTemperature] = useState<number>(() => {
    const savedTemperature = sessionStorage.getItem("temperature");
    return savedTemperature !== null ? parseInt(savedTemperature, 10) : 25;
  });
  const [maxTokens, setMaxTokens] = useState<number>(() => {
    const savedMaxTokens = sessionStorage.getItem("maxTokens");
    return savedMaxTokens !== null ? parseInt(savedMaxTokens, 10) : 12;
  });

  const [topP, setTopP] = useState<number>(() => {
    const savedTopP = sessionStorage.getItem("topP");
    return savedTopP !== null ? parseFloat(savedTopP) : 1;
  });

  const [stopSequences, setStopSequences] = useState<string>(() => {
    const savedStopSequences = sessionStorage.getItem("stopSequences");
    return savedStopSequences || "";
  });
  const [frequencyPenalty, setFrequencyPenalty] = useState<number>(() => {
    const savedFrequencyPenalty = sessionStorage.getItem("frequencyPenalty");
    return savedFrequencyPenalty !== null
      ? parseFloat(savedFrequencyPenalty)
      : 0;
  });

  const [presencePenalty, setPresencePenalty] = useState<number>(() => {
    const savedPresencePenalty = sessionStorage.getItem("presencePenalty");
    return savedPresencePenalty !== null ? parseFloat(savedPresencePenalty) : 0;
  });

  useEffect(() => {
    sessionStorage.setItem("systemInstructions", systemInstructions);
  }, [systemInstructions]);

  useEffect(() => {
    sessionStorage.setItem("temperature", temperature.toString());
  }, [temperature]);

  useEffect(() => {
    sessionStorage.setItem("maxTokens", maxTokens.toString());
  }, [maxTokens]);

  useEffect(() => {
    sessionStorage.setItem("topP", topP.toString());
  }, [topP]);

  useEffect(() => {
    sessionStorage.setItem("frequencyPenalty", frequencyPenalty.toString());
  }, [frequencyPenalty]);

  useEffect(() => {
    sessionStorage.setItem("presencePenalty", presencePenalty.toString());
  }, [presencePenalty]);

  //for llm

  useEffect(() => {
    sessionStorage.setItem("selectedModel", selectedModel);
  }, [selectedModel]);

  useEffect(() => {
    sessionStorage.setItem("selectedCreator", selectedCreator);
  }, [selectedCreator]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("hasSentInitialMessage");
    };
  }, []);

  return (
    <>
      <div className="welcome-section">
        <div className="welcome-section2">
          <h1>Welcome to Panels AI</h1>&nbsp;
          <img src={logo} alt="Logo" className="welcome-logo" />
        </div>
        <div className="search-bar">
          {/* <Dropdownllm
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            selectedCreator={selectedCreator}
            setSelectedCreator={setSelectedCreator}
            selectedLLM={selectedCreator + ": " + selectedModel}
            setSelectedLLM={setSelectedLLM}
          /> */}

          <InputText
            inputText={inputText}
            setInputText={setInputText}
            handleButtonClick={handleButtonClick}
            // isLoading={isLoading}
            // setIsLoading={setIsLoading}
            // sendMessage={sendMessage}
            selectedCreator={selectedCreator}
            selectedChatId={selectedChatId}
            chats={chats}
            updateChatMessages={updateChatMessages}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            startNewChat={handleNewChat}
          />
        </div>
        <div className="advanced-settings">
          <Dialog.Root>
            <hr />
            <Dialog.Trigger asChild>
              <a type="button" className="welcome-advance-settings">
                <Image source={settingImage} alternate={"settingImage"} />
                Advance Settings
              </a>
            </Dialog.Trigger>
            <hr />
            <Dialog.Portal>
              <Dialog.Overlay className="DialogOverlay" />
              <Dialog.Content className="DialogContent">
                <Dialog.Title className="DialogTitle">
                  Advance Settings
                </Dialog.Title>
                {/* <Dialog.Description className="DialogDescription">
                Make changes to your profile here. Click save when you're done.
              </Dialog.Description> */}

                <SettingsPanel
                  // onSettingsChange={handleSettingsChange}
                  systemInstructions={systemInstructions}
                  setSystemInstructions={setSystemInstructions}
                  temperature={temperature}
                  setTemperature={setTemperature}
                  maxTokens={maxTokens}
                  setMaxTokens={setMaxTokens}
                  topP={topP}
                  setTopP={setTopP}
                  stopSequences={stopSequences}
                  setStopSequences={setStopSequences}
                  frequencyPenalty={frequencyPenalty}
                  setFrequencyPenalty={setFrequencyPenalty}
                  presencePenalty={presencePenalty}
                  setPresencePenalty={setPresencePenalty}
                />

                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "flex-end",
                  }}
                >
                  {/* <Dialog.Close asChild>
                  <button className="Button green">Save changes</button>
                </Dialog.Close> */}
                </div>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close">
                    <img
                      src={crossicon}
                      alt="icon"
                      style={{
                        width: "20px",
                        height: "20px",

                        filter: "invert(1)",
                      }}
                    />
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </div>

        <SuggestedTopics />
      </div>
    </>
  );
};

export default WelcomeSection;
