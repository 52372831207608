import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles.css";
import { AuthProvider } from "./components/AuthContext";
import { AppProvider } from "./components/AppContext";

const RootComponent: React.FC = () => {
  return (
    <React.StrictMode>
      <AuthProvider>
        <AppProvider>
          <App />
        </AppProvider>
      </AuthProvider>
    </React.StrictMode>
  );
};
// const selectedModel = ""; // Replace with actual initial model
// const setSelectedModel = (model: string) => {
//   model = "Claude";
// }; // Replace with actual setter
// const selectedChatId = 1; // Replace with actual chat ID
// const chats = []; // Replace with actual chats data
// const updateChatMessages = (chatId: number, messages: any[]) => {
//   (chatId = 1), messages;
// }; // Replace with actual update function

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <RootComponent
  // selectedChatId={selectedChatId}
  // chats={chats}
  // updateChatMessages={updateChatMessages}
  // selectedModel={selectedModel}
  // setSelectedModel={setSelectedModel}
  />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
