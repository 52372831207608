import React, { useState, useEffect, useRef, ForwardedRef } from "react";
import "../styles.css";
import logo from "../assets/logo.png";
import profpic from "../assets/image.jpeg";
import share from "../assets/share.png";
import more from "../assets/more.png";
import axios from "axios";
import { User } from "lucide-react";
import Dropdownllm from "./Llm";
import Anthropic from "@anthropic-ai/sdk";
import { useLocation, useNavigate } from "react-router-dom";
import SendButton from "./SendButton";
import { useAuth } from "./AuthContext";
import JellyOozeLoader from "./Loader";
import { encode } from "gpt-tokenizer";

import * as Dialog from "@radix-ui/react-dialog";
import crossicon from "src/assets/cross-2.svg";

export interface Message {
  id: number;
  text: string;
  role: "user" | "assistant";
  isNew: boolean;
}

export interface ChatHistory {
  // thread_id: number;
  id: number;
  title: string;
  messages: Message[];
}

export interface InputTextProps {
  inputText?: string;
  setInputText?: React.Dispatch<React.SetStateAction<string>>;
  // isLoading: boolean;
  // setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  // sendMessage: () => Promise<void>;
  selectedCreator?: string;

  selectedModel?: string;
  setSelectedModel?: React.Dispatch<React.SetStateAction<string>>;
  selectedChatId?: number | null;
  chats?: ChatHistory[];
  updateChatMessages?: (chatId: number, messages: Message[]) => void;
  handleButtonClick?: () => void;
  startNewChat?: () => void;
  sendMessageButtonRef?: ForwardedRef<HTMLButtonElement>;
  onChatLoaded?: () => void;
}

const tokenLimits = [
  {
    model: "gpt-3.5-turbo-0125",
    limit: 16385,
  },
  {
    model: "gpt-4",
    limit: 8192,
  },
  {
    model: "gpt-4-turbo",
    limit: 128000,
  },
  {
    model: "gpt-4o-mini",
    limit: 128000,
  },
  {
    model: "gpt-4o",
    limit: 128000,
  },
  {
    model: "claude-3-5-sonnet-20240620",
    limit: 200000,
  },
  {
    model: "claude-3-opus-20240229",
    limit: 200000,
  },
  {
    model: "claude-3-haiku-20240307",
    limit: 200000,
  },
];

const InputText: React.FC<InputTextProps> = ({
  inputText,
  setInputText,
  // isLoading,
  // setIsLoading,
  // sendMessage,
  selectedCreator,
  selectedModel,
  setSelectedModel,
  selectedChatId,
  chats,
  updateChatMessages,
  handleButtonClick,
  startNewChat = () => {},
  sendMessageButtonRef,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { session } = useAuth();

  const [isLimit, setIsLimit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const openDialog = () => setIsOpen(true);

  const user_id = session?.user?.user_metadata.user_id;

  let model_id;
  switch (selectedModel) {
    // case "gpt-3.5-turbo":
    //   model_id = 5;
    //   break;
    case "gpt-4o":
      model_id = 2;
      break;
    case "gpt-4-turbo":
      model_id = 3;
      break;
    case "gpt-4":
      model_id = 4;
      break;
    case "claude-3-5-sonnet-20240620":
      model_id = 8;
      break;
    case "gpt-3.5-turbo-0125":
      model_id = 9;
      break;
    case "gpt-4o-mini":
      model_id = 11;
      break;
    case "claude-3-opus-20240229":
      model_id = 13;
      break;
    case "claude-3-haiku-20240307":
      model_id = 14;
      break;
    default:
      model_id = 9;
      break;
  }

  const handleSettingsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case "inputText":
        setInputText && setInputText(value);
        break;
      default:
        break;
    }
  };

  // if (handleButtonClick != null) {
  //   handleButtonClick();
  //   //   //navigate
  //   console.log("created new chat");
  //   return;
  // }

  //navigate after render
  useEffect(() => {
    if (
      selectedChatId != null &&
      handleButtonClick != null &&
      inputText.trim() != ""
    ) {
      handleButtonClick(); //navigate to chats
    }
  }, [handleButtonClick, navigate]);

  const sendMessage = async () => {
    if (!session) {
      alert("signup and sign in to use panelsAI");
      return;
    }

    console.log("User ID:", user_id);
    console.log(chats);
    console.log(selectedChatId);
    console.log("InputTextInputTextInputTextInputText", inputText);

    if (inputText.trim() != "") {
      if (selectedModel != "Model" && selectedCreator != "Creator")
        startNewChat();
      console.log("created new chat");
    } else {
      alert("Please input text");
      return;
    }

    //ensure chatID exist in welcomescreen
    // if (selectedChatId === null) {
    //   startNewChat();
    //   console.log(selectedChatId);
    //   return;
    // }

    if (selectedCreator == "OpenAI") {
      console.log("selectedModel", selectedModel);
      if (selectedModel == "Model") {
        alert("Please select a Model");
      } else {
        await generateOpenAI();

        console.log("model is openai");
      }
    } else if (selectedCreator == "Anthropic") {
      console.log("selectedModel", selectedModel);
      if (selectedModel == "Model") {
        alert("Please select a Model");
      } else {
        console.log("model is anthropic");

        // alert("anthropic is still not working");
        await generateAnthropic();
      }
    } else {
      return;
    }
  };

  const [tokenLimitReach, setTokenLimitReach] = useState(false);

  const generateOpenAI = async () => {
    let toltaltoken = 0;

    const chat = chats.find((chat) => chat.id === selectedChatId);

    console.log("chat", chat);

    if (!chat) {
      // alert("Open a new chat");
      return;
    }

    const limit = tokenLimits.find(
      (model) => model.model === selectedModel
    ).limit;

    const tokens = encode(inputText).length;
    if (tokens >= limit) {
      console.log("limit", limit);
      console.log("tokens", tokens);
      console.log("exceeding the token limit ERROR");
      openDialog();
      setInputText("");
      sessionStorage.removeItem("inputText");
      return;
    }
    const highestId = chat.messages.reduce(
      (maxId, message) => Math.max(maxId, message.id),
      0
    );
    const newMessage: Message = {
      id: highestId + 1,
      text: inputText,
      role: "user",
      isNew: true,
    };

    console.log("chat.messages", chat.messages);
    let updatedMessages = [...chat.messages, newMessage];

    console.log("updatedMessages", updatedMessages);

    const loaderMessage: Message = {
      id: newMessage.id + 1,
      text: "loading", // You will replace this with the loader component in your UI
      role: "assistant",
      isNew: true,
    };

    let messagesWithLoader = [...updatedMessages, loaderMessage];
    updateChatMessages(chat.id, messagesWithLoader);
    setIsLoading(true);

    console.time("Fetch Openai Response");
    try {
      // fetch the logs of threadID of user using chatID shit
      let messageContext = [];

      messageContext = await fetchChatThreadIDLogs(
        chat.id,
        user_id,
        inputText,
        tokenLimitReach
      );

      console.log("messageContext before call", messageContext);

      console.log("tokenLimitReach", tokenLimitReach);

      if (tokenLimitReach) {
        const userLastMessage = chat.messages[chat.messages.length - 2];
        const aiLastMessage = chat.messages[chat.messages.length - 1];

        chat.messages = [userLastMessage, aiLastMessage];
        console.log(
          "chat.messageschat.messages",
          chat.messages[chat.messages.length - 1]
        );
        console.log("chat.messageschat.messages", chat);

        updatedMessages = [...chat.messages, newMessage];
        console.log("updatedMessagesupdatedMessages", updatedMessages);

        messagesWithLoader = [...updatedMessages, loaderMessage];

        updateChatMessages(chat.id, messagesWithLoader);

        sessionStorage.setItem("chats", JSON.stringify(chats));

        console.log("madapaking chats", chats);
      }

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: selectedModel,
          messages: messageContext,
        },
        {
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_OPENAI_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );
      const botMessage: Message = {
        id: loaderMessage.id,
        text: response.data.choices[0].message.content,
        role: "assistant",
        isNew: true,
      };
      const finalMessages = [...updatedMessages, botMessage];
      console.timeEnd("Fetch Openai Response");
      updateChatMessages(chat.id, finalMessages);
      console.log("finalMessages", finalMessages);
      setIsLoading(false);

      sessionStorage.removeItem("inputText");

      const completion_tokens = response.data.usage.completion_tokens;
      const prompt_tokens = response.data.usage.prompt_tokens;
      const total_token = completion_tokens + prompt_tokens;

      console.log("completion_tokens", completion_tokens);
      //reset the history logs
      if (total_token >= limit - 3000) {
        setTokenLimitReach(!tokenLimitReach);
      }

      const updateUserTokenPromise = axios
        .post(`${process.env.REACT_APP_SERVER_ACCESS}update-user-token`, {
          // total_tokens: total_tokens,
          completion_tokens: completion_tokens,
          prompt_tokens: prompt_tokens,
          user_id: user_id,
          selectedModel: selectedModel,
          selectedCreator: selectedCreator,
        })
        .catch((error) => {
          console.log("Error updating user token:", error);
        });

      const saveQtoDBPromise = saveQtoDB(
        finalMessages,
        chat.id,
        total_token,
        completion_tokens,
        prompt_tokens,
        model_id,
        tokenLimitReach
      );

      await Promise.all([updateUserTokenPromise, saveQtoDBPromise]);
    } catch (error) {
      console.error("Error sending message:", error);
      setIsLoading(false);
      sessionStorage.removeItem("inputText");
    }
  };

  const fetchChatThreadIDLogs = async (
    chat_id,
    user_id,
    inputText,
    tokenLimitReach
  ) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "getThreadId";

    const response = await axios.get(urlEnv, {
      params: {
        chat_id: chat_id,
        user_id: user_id,
      },
    });
    let newMessage = [];
    let messageContext = [];
    if (response.data) {
      // Format past messages
      messageContext = response.data.log.map((msg) => ({
        role: msg.role === "user" ? "user" : "assistant",
        content: msg.text,
      }));
    }

    if (tokenLimitReach) {
      setTokenLimitReach(false);
      messageContext = messageContext.slice(-2);
      // sessionStorage.setItem("chats", JSON.stringify(messageContext));
      alert("conversation history UPDATED!");
      console.log("fuccccccccccck");
    }

    // Add current input text to the context
    messageContext.push({ role: "user", content: inputText });
    setInputText("");
    return messageContext;
  };
  const generateAnthropic = async () => {
    let toltaltoken = 0;
    const chat = chats.find((chat) => chat.id === selectedChatId);

    if (!chat) return; // Exit early if no chat is selected

    const limit = tokenLimits.find(
      (model) => model.model === selectedModel
    ).limit;

    const tokens = encode(inputText).length;
    if (tokens >= limit) {
      console.log("limit", limit);
      console.log("tokens", tokens);
      console.log("exceeding the token limit ERROR");
      openDialog();
      setInputText("");
      sessionStorage.removeItem("inputText");
      return;
    }
    const highestId = chat.messages.reduce(
      (maxId, message) => Math.max(maxId, message.id),
      0
    );
    const newMessage: Message = {
      id: highestId + 1,
      text: inputText,
      role: "user",
      isNew: true,
    };

    let updatedMessages = [...chat.messages, newMessage];

    const loaderMessage: Message = {
      id: newMessage.id + 1,
      text: "loading", // You will replace this with the loader component in your UI
      role: "assistant",
      isNew: true,
    };

    let messagesWithLoader = [...updatedMessages, loaderMessage];
    updateChatMessages(chat.id, messagesWithLoader);

    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "callanthropic";

    setIsLoading(true);

    if (tokenLimitReach) {
      const userLastMessage = chat.messages[chat.messages.length - 2];
      const aiLastMessage = chat.messages[chat.messages.length - 1];

      chat.messages = [userLastMessage, aiLastMessage];
      console.log(
        "chat.messageschat.messages",
        chat.messages[chat.messages.length - 1]
      );
      console.log("chat.messageschat.messages", chat);

      updatedMessages = [...chat.messages, newMessage];
      console.log("updatedMessagesupdatedMessages", updatedMessages);

      messagesWithLoader = [...updatedMessages, loaderMessage];

      updateChatMessages(chat.id, messagesWithLoader);

      sessionStorage.setItem("chats", JSON.stringify(chats));

      console.log("madapaking chats", chats);
    }

    try {
      // Fetch logs and call the API in parallel
      const [response] = await Promise.all([
        axios.post(
          urlEnv,
          {
            model: selectedModel,
            messages: await fetchChatThreadIDLogs(
              chat.id,
              user_id,
              inputText,
              tokenLimitReach
            ),
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_ANTHROPIC_API_KEY,
              "Content-Type": "application/json",
            },
          }
        ),
      ]);

      const botMessage: Message = {
        id: loaderMessage.id,
        text: response.data.content[0].text,
        role: "assistant",
        isNew: true,
      };

      const finalMessages = [...updatedMessages, botMessage];

      setIsLoading(false);
      sessionStorage.removeItem("inputText");

      const completion_tokens = response.data.usage.output_tokens;
      const prompt_tokens = response.data.usage.input_tokens;
      const total_token = completion_tokens + prompt_tokens;

      if (total_token >= limit - 3000) {
        setTokenLimitReach(!tokenLimitReach);
      }

      const updateUserTokenPromise = axios
        .post(`${process.env.REACT_APP_SERVER_ACCESS}update-user-token`, {
          // total_tokens: total_tokens,
          completion_tokens: completion_tokens,
          prompt_tokens: prompt_tokens,
          user_id: user_id,
          selectedModel: selectedModel,
          selectedCreator: selectedCreator,
        })
        .catch((error) => {
          console.log("Error updating user token:", error);
        });

      const saveQtoDBPromise = saveQtoDB(
        finalMessages,
        chat.id,
        total_token,
        completion_tokens,
        prompt_tokens,
        model_id,
        tokenLimitReach
      );

      // Parallel execution of update and other operations
      await Promise.all([
        updateChatMessages(chat.id, finalMessages),
        updateUserTokenPromise,
        saveQtoDBPromise,
      ]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
      sessionStorage.removeItem("inputText");
    }
  };

  const saveQtoDB = async (
    finalMessages,
    chatID,
    total_token_used,
    completion_tokens,
    prompt_tokens,
    model_id,
    tokenLimitReach
  ) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "saveThread";
    try {
      const response = await axios.post(urlEnv, {
        log: finalMessages,
        user_id: user_id,
        model_id: model_id,
        chat_id: chatID,
        total_token_used: total_token_used,
        completion_tokens: completion_tokens,
        prompt_tokens: prompt_tokens,
        selectedCreator: selectedCreator,
        selectedModel: selectedModel,
        tokenLimitReach: tokenLimitReach,
      });
    } catch (error) {
      if (error.response) {
        console.error("Error saving query:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up request:", error.message);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Allow new line with Shift + Enter
        return;
      } else {
        // Prevent default Enter behavior and send message
        e.preventDefault();
        sendMessage();
      }
    }
  };
  const textareaRef = useRef(null);
  useEffect(() => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto"; // Reset height to auto to shrink if necessary
    textarea.style.height = `${textarea.scrollHeight + 1}px`; // Set height to scroll height
  }, [inputText]);

  return (
    <div className="flex-div">
      <div className="input-container">
        <textarea
          ref={textareaRef}
          placeholder="Ask Panels something"
          name="inputText"
          value={inputText}
          onKeyDown={handleKeyDown}
          onChange={handleSettingsChange}
          disabled={isLoading}
          rows={1}
        />
        <SendButton
          ref={sendMessageButtonRef}
          onClick={sendMessage}
          disabled={isLoading}
        />
      </div>
      <Dialog.Root
        open={isOpen}
        onOpenChange={(open) => {
          setIsOpen(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>
        <Dialog.Portal>
          <div className="overlay"></div>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogCustomWidth-signup">
            <Dialog.Title className="DialogTitle"></Dialog.Title>
            <Dialog.Description className="DialogDescription"></Dialog.Description>

            <div className="subscription-panel">
              <h2>Error generating response</h2>
              <p className="description">
                The message you are trying to submit is too long. Please submit
                something shorter.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild></Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <img
                  src={crossicon}
                  alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",

                    filter: "invert(1)",
                  }}
                />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};
export default InputText;
