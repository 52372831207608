import axios from "axios";
import { lookup } from "dns";
import { Session } from "inspector";
import React, { useEffect, useState } from "react";
import "src/modal.css";
import { useAuth } from "./AuthContext";
import crossicon from "src/assets/cross-2.svg";
import * as Dialog from "@radix-ui/react-dialog";
import { useNavigate } from "react-router-dom";
const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

const SubscriptionPanel = () => {
  const { session } = useAuth();
  const monthly = async () => {
    // const form = document.getElementById("checkout-form") as HTMLFormElement;
    // if (form) {
    //   console.log("Submitting form...");
    //   form.submit();
    // }
    const user_id = session?.user?.user_metadata.user_id;

    console.log(user_id);
    console.log("urlEnvurlEnvurlEnvurlEnv", urlEnv);

    const response = await axios.post(`${urlEnv}create-checkout-session`, {
      product_id: "price_1PnLURISnPjRR9tdWyAesxrG",
      user_id: user_id,
    });
    console.log(response);
    window.location.href = response.data.url;
  };
  const navigation = useNavigate();
  const navlogin = () => {
    window.location.href = "/";
  };

  return (
    <>
      <div className="subscription-panel">
        <h2>Subscribe to PanelsAI</h2>
        <p className="description">
          Subscribe to PanelsAI to unlock access to all bots, including OpenAI's
          GPT-4 and Anthropic's Claude-2-100k.
        </p>
        <div className="options">
          <div className="option yearly">
            <div className="dflexbetween">
              <p className="title">Yearly</p>
              <p className="save">Best value - save 17%</p>
            </div>
            <div className="dflex ">
              <p className="old-price price">$19.99/mo</p>
              <p className="price">$16.67/mo</p>
            </div>
            <div className="dflex">
              <p className="old-price total-price-old">$239.88/yr</p>
              <p className="total-price">$199.99/yr</p>
            </div>
          </div>
          <div className="option monthly hov">
            <div className="dflexbetween">
              <p className="title">Monthly</p>
            </div>

            <Dialog.Root>
              {session ? (
                <div onClick={monthly} className="dflexbetween">
                  <p className="price">$19.99/mo</p>
                </div>
              ) : (
                <Dialog.Trigger asChild>
                  <div className="dflexbetween">
                    <p className="price">$19.99/mo</p>
                  </div>
                </Dialog.Trigger>
              )}

              <Dialog.Portal>
                <div className="overlay"></div>
                <Dialog.Overlay className="DialogOverlay" />
                <Dialog.Content className="DialogContent">
                  <Dialog.Title className="DialogTitle"></Dialog.Title>
                  <Dialog.Description className="DialogDescription"></Dialog.Description>
                  <h2 className="h2modal">login to subscribe</h2>
                  To avail the panelsAI subscription, please login first.
                  <div
                    style={{
                      display: "flex",
                      marginTop: 25,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Dialog.Close asChild>
                      <div
                        style={{
                          display: "flex",
                          marginTop: 15,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Dialog.Close asChild>
                          <button className="btnCloseModal" onClick={navlogin}>
                            Go to login now
                          </button>
                        </Dialog.Close>
                      </div>
                    </Dialog.Close>
                  </div>
                  <Dialog.Close asChild>
                    <button className="IconButton" aria-label="Close">
                      <img
                        src={crossicon}
                        alt="icon"
                        style={{
                          width: "20px",
                          height: "20px",

                          filter: "invert(1)",
                        }}
                      />
                    </button>
                  </Dialog.Close>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>

            <div className="dflexbetween">
              <p className="total-price">$239.88/yr</p>
            </div>
          </div>
        </div>
        <p className="footer-subscription">
          By subscribing, you'll enroll in automatic payments of $199.99/year
          (plus tax, if applicable). This includes unlimited messaging on
          popular bots such as ChatGPT and Claude-instant, and up to 600 and
          1000 messages on advanced bots like GPT-4 and Claude-2-100k. Once
          messaging limits are reached, messaging speed, quality, or bot
          availability may be reduced. Manage or cancel your subscription via
          Stripe. Subscriber terms apply.
        </p>
      </div>
    </>
  );
};

const SuccessDisplay = ({ sessionId }) => {
  console.log("sessionIdasd", sessionId);
  return (
    <div className="subscription-panel">
      <h2>Subscription to starter plan successful!</h2>
      <form action={`${urlEnv}create-portal-session`} method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <button id="checkout-and-portal-button" type="submit">
          Manage your billing information
        </button>
      </form>
    </div>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

// const check = async (sessionId) => {
//   console.log(sessionId);
//   try {
//     const response = await axios.post(`${urlEnv}check-session-id`, {
//       session_id: sessionId,
//     });
//     console.log("response", response);
//   } catch (error) {
//     console.log("front end error s", error);
//   }
// };

function SubsPanel() {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  if (!success && message === "") {
    return <SubscriptionPanel />;
  } else if (success && sessionId !== "") {
    // const response = await check(sessionId);
    // console.log("response function", response);
    return;
    // (
    //   <>
    //     <SubscriptionPanel /> <SuccessDisplay sessionId={sessionId} />
    //   </>
    // );
  } else {
    return (
      <>
        <Message message={message} />
        <SubscriptionPanel />
      </>
    );
  }
}

export default SubsPanel;
