import React, { useState, useEffect, Component } from "react";
import "../chatscreen.css";
import { margin } from "@mui/system";
import Dropdownllm from "./Llm";
import { useAppContext } from "./AppContext";

interface SettingsPanelProps {
  onSettingsChange: (settings: any) => void;
}

interface SettingsPanelProps2nd {
  systemInstructions?: string;
  setSystemInstructions?: React.Dispatch<React.SetStateAction<string>>;
  temperature?: number;
  setTemperature?: React.Dispatch<React.SetStateAction<number>>;
  maxTokens?: number;
  setMaxTokens?: React.Dispatch<React.SetStateAction<number>>;
  topP?: number;
  setTopP?: React.Dispatch<React.SetStateAction<number>>;
  stopSequences?: string;
  setStopSequences?: React.Dispatch<React.SetStateAction<string>>;
  frequencyPenalty?: number;
  setFrequencyPenalty?: React.Dispatch<React.SetStateAction<number>>;
  presencePenalty?: number;
  setPresencePenalty?: React.Dispatch<React.SetStateAction<number>>;
}

type CombinedProps = SettingsPanelProps & SettingsPanelProps2nd;

const SettingsPanel: React.FC<SettingsPanelProps2nd> = ({
  // onSettingsChange,
  systemInstructions,
  setSystemInstructions,
  temperature,
  setTemperature,
  maxTokens,
  setMaxTokens,
  topP,
  setTopP,
  stopSequences,
  setStopSequences,
  frequencyPenalty,
  setFrequencyPenalty,
  presencePenalty,
  setPresencePenalty,
}) => {
  // const [systemInstructions, setSystemInstructions] = useState("");
  // const [temperature, setTemperature] = useState(25);
  // const [maxTokens, setMaxTokens] = useState(12);
  // const [topP, setTopP] = useState(1);
  // const [stopSequences, setStopSequences] = useState("");
  // const [frequencyPenalty, setFrequencyPenalty] = useState(0);
  // const [presencePenalty, setPresencePenalty] = useState(0);

  const handleSettingsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case "systemInstructions":
        setSystemInstructions && setSystemInstructions(value);
        break;
      case "temperature":
        setTemperature && setTemperature(Number(value));
        break;
      case "maxTokens":
        setMaxTokens && setMaxTokens(Number(value));
        break;
      case "topP":
        setTopP && setTopP(Number(value));
        break;
      case "stopSequences":
        setStopSequences && setStopSequences(value);
        break;
      case "frequencyPenalty":
        setFrequencyPenalty && setFrequencyPenalty(Number(value));
        break;
      case "presencePenalty":
        setPresencePenalty && setPresencePenalty(Number(value));
        break;
      default:
        break;
    }
  };

  const {
    selectedModel,
    setSelectedModel,
    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
  } = useAppContext();

  return (
    <div className="settings-panel">
      <div className="margin-t">
        <Dropdownllm
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          selectedCreator={selectedCreator}
          setSelectedCreator={setSelectedCreator}
          selectedLLM={selectedLLM}
          setSelectedLLM={setSelectedLLM}
        />
      </div>
      <div className="settings-panel-scroll">
        <div className="setting-col ">
          <label className="label-pad" htmlFor="system-instructions">
            System
          </label>
          {/*input tag was changed to textarea*/}
          <textarea
            className="setting-textarea"
            placeholder="Enter system instruction"
            id="system-instructions"
            name="systemInstructions"
            value={systemInstructions}
            // onChange={(e) => setSystemInstructions(e.target.value)}
            onChange={handleSettingsChange}
          ></textarea>
        </div>
        <div className="setting-col">
          <label htmlFor="temperature" className="label-pad">
            Temperature<span className="text-right">{temperature}</span>
          </label>

          <input
            type="range"
            className="color-accent"
            id="temperature"
            min="0"
            max="100"
            name="temperature"
            value={temperature}
            // onChange={(e) => setTemperature(parseInt(e.target.value, 10))}
            onChange={handleSettingsChange}
          />
        </div>
        <div className="setting-col">
          <label htmlFor="max-tokens" className="label-pad">
            Maximum Tokens<span className="text-right">{maxTokens}</span>
          </label>
          <input
            type="range"
            className="color-accent"
            id="max-tokens"
            min="1"
            max="100"
            name="maxTokens"
            value={maxTokens}
            // onChange={(e) => setMaxTokens(parseInt(e.target.value, 10))}
            onChange={handleSettingsChange}
          />
        </div>
        <div className="setting-col">
          <label htmlFor="top-p" className="label-pad">
            Top P<span className="text-right">{topP}</span>
          </label>
          <input
            type="range"
            className="color-accent"
            id="top-p"
            min="0"
            max="1"
            step="0.1"
            name="topP"
            value={topP}
            //onChange={(e) => setTopP(parseFloat(e.target.value))}
            onChange={handleSettingsChange}
          />
        </div>
        <div className="setting-col">
          <label htmlFor="stop-sequences" className="diff-pad">
            Stop Sequences
          </label>
          <label className="label-pad sequence-text-size">
            Enter sequence and press tab
          </label>
          {/*input tag was changed to textarea*/}
          <textarea
            className="setting-textarea"
            id="stop-sequences"
            name="stopSequences"
            value={stopSequences}
            // onChange={(e) => setStopSequences(e.target.value)}
            onChange={handleSettingsChange}
          ></textarea>
        </div>
        <div className="setting-col">
          <label className="label-pad" htmlFor="frequency-penalty">
            Frequency Penalty
            <span className="text-right">{frequencyPenalty}</span>
          </label>
          <input
            type="range"
            className="color-accent"
            id="frequency-penalty"
            name="frequencyPenalty"
            min="0"
            max="10"
            value={frequencyPenalty}
            // onChange={(e) => setFrequencyPenalty(parseInt(e.target.value, 10))}
            onChange={handleSettingsChange}
          />
        </div>
        <div className="setting-col">
          <label className="label-pad" htmlFor="presence-penalty">
            Presence Penalty
            <span className="text-right">{presencePenalty}</span>
          </label>
          <input
            type="range"
            className="color-accent"
            id="presence-penalty"
            name="presencePenalty"
            min="0"
            max="10"
            value={presencePenalty}
            // onChange={(e) => setPresencePenalty(parseInt(e.target.value, 10))}
            onChange={handleSettingsChange}
          />
        </div>
        <div className="setting-col setting-col-footer">
          <footer className="setting-footer">
            <p className="s-f-paragraph">
              API request and other third-party integrations will not be used to
              train models.
            </p>
            <a href="#" className="s-f-paragraph green-color">
              Learn more
            </a>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default SettingsPanel;
