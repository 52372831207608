// TypingEffect.tsx
import React, { useEffect, useState } from "react";
import CursorSVG from "./CursorSVG";
import "../TypingAnimation.css";
import logo192 from "../assets/logo192.png";
// import { MarkdownRenderer } from "./ChatComponent";
import { height } from "@mui/system";
import ReactMarkdown from "react-markdown";

const MarkdownRenderer: React.FC<{ content: string }> = ({ content }) => {
  return (
    <div>
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
};

const TypingEffect: React.FC<{
  text: string;
  onTypingComplete: () => void;
}> = ({ text, onTypingComplete }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);

  useEffect(() => {
    if (typingIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText((prev) => prev + text[typingIndex]);
        setTypingIndex((prev) => prev + 1);
      }, 10); // Adjust typing speed here
      return () => clearTimeout(timer);
    } else {
      onTypingComplete();
    }
  }, [typingIndex, text, onTypingComplete]);

  return (
    <>
      <MarkdownRenderer content={displayedText} />
      <svg className="svg-rotate" viewBox="0 0 16 16">
        <image href={logo192} className="svg-image" />
      </svg>
    </>
  );
};

export default TypingEffect;
