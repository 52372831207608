import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Divider,
  CssBaseline,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Typography,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import Settings from "./Settings";
import { height, styled, useTheme } from "@mui/system";
import NavigationBar from "../screens/Navigationbar";
// import "src/chatwindow.css";
import {
  InputTextProps,
  ChatHistory,
  Message,
} from "../components/SendMessage";
import ChatComponent from "../components/ChatComponent";
import Sidebar from "./SideBar";
import SettingsPanel from "./SettingsPanel";
import axios from "axios";
import { AuthProvider, useAuth } from "./AuthContext";

interface ContainerProps {
  leftOpen: boolean;
  rightOpen: boolean;
}
const drawerWidth = 306;
const Root = styled("div")({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  backgroundColor: "#121212",
});

const TopAppBar = styled(AppBar)({
  zIndex: 1201,
  backgroundColor: "#1f1f1f",
});

const SecondaryAppBar = styled(AppBar)({
  top: "64px",
  zIndex: 1200,
  backgroundColor: "#1f1f1f",
});

const ContentContainer = styled("div")<{
  leftOpen: boolean;
  rightOpen: boolean;
}>(({ leftOpen, rightOpen }) => ({
  display: "flex",
  flexGrow: 1,
  margin: "70px 20px 0 20px", // Adjust based on the height of both app bars
  transition:
    "margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin-right 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",

  marginLeft: leftOpen ? `${drawerWidth}px` : "0px",
  marginRight: rightOpen ? `${drawerWidth}px` : "0px",
  backgroundColor: "#1f1f1f",
}));

const MainContent = styled("main")<{
  leftOpen: boolean;
  rightOpen: boolean;
}>(({ leftOpen, rightOpen }) => ({
  flexGrow: 1,
  padding: "16px 16px 0",
  transition:
    "margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin-right 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",

  backgroundColor: "#121212",
}));

const SideDrawer = styled(Drawer)<{
  variant: "persistent";
  anchor: "left" | "right";
  open: boolean;
}>(({ theme, anchor }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    marginTop: "0", // Start just below the two app bars
    [theme.breakpoints.up("sm")]: {
      top: "0",
    },
    backgroundColor: "#222",
  },
  zIndex: 1100,
}));
/* separated style component */
/*menu*/
const IconContainer1 = styled("div")<{
  leftOpen: boolean;
}>(({ leftOpen }) => ({
  position: "absolute",
  left: "0",
  top: "74px",
  bottom: "0",
  padding: "8px",
  // zIndex: 1201,
  color: "#F6F7F9",
}));

/*settings*/
const IconContainer2 = styled("div")<{
  rightOpen: boolean;
}>(({ rightOpen }) => ({
  position: "absolute",
  right: "0",
  top: "74px",
  padding: "8px",
  // zIndex: 1201,
  color: "#F6F7F9",
}));

export function CloseButton({ handler, rightORleft }) {
  return (
    <a type="button" className={rightORleft} onClick={handler}>
      X
    </a>
  );
}

const ChatScreenWindow: React.FC<InputTextProps> = ({
  selectedModel,
  setSelectedModel,
}) => {
  const { session } = useAuth();

  const user_id = session?.user?.user_metadata.user_id;
  const navigate = useNavigate();

  const theme = useTheme();
  const [leftOpen, setLeftOpen] = React.useState(false);
  const [rightOpen, setRightOpen] = React.useState(false);
  // const [chats, setChats] = useState<ChatHistory[]>([]);

  //this will save the chats to local/ using cache
  const [chats, setChats] = useState<ChatHistory[]>(() => {
    const savedChats = sessionStorage.getItem("chats");
    return savedChats ? JSON.parse(savedChats) : [];
  });
  useEffect(() => {
    sessionStorage.setItem("chats", JSON.stringify(chats));
  }, [chats]);

  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const initialLoad = useRef(true);
  const location = useLocation();
  const fromWelcomeScreen = location.state?.fromWelcomeScreen;
  useEffect(() => {
    const chatExists = chats.length > 0;
    const chatInitialized =
      sessionStorage.getItem("chatInitialized") === "true";
    if (
      !fromWelcomeScreen &&
      initialLoad.current &&
      !chatExists &&
      !chatInitialized
    ) {
      initialLoad.current = false;
      // Find the maximum existing ID
      const maxId = chats.reduce((max, chat) => Math.max(max, chat.id), -0);
      const newChat: ChatHistory = {
        // thread_id: 263,
        id: maxId + 1,
        title: `Chat ${maxId + 1}`,
        messages: [
          // {
          //   id: 0,
          //   text: "Hello! How can I assist you today?",
          //   role: "assistant",
          // },
        ],
      };
      setChats((prevChats) => [...prevChats, newChat]);
      setSelectedChatId(newChat.id);
      sessionStorage.setItem("chatInitialized", "true");
    }
  }, []);

  const handleLeftDrawerToggle = () => {
    setLeftOpen(!leftOpen);
  };

  const handleRightDrawerToggle = () => {
    setRightOpen(!rightOpen);
  };

  const updateChatMessages = (chatId: number, messages: Message[]) => {
    const updatedChats = chats.map((chat) => {
      if (chat.id === chatId) {
        return { ...chat, messages };
      }
      return chat;
    });
    setChats(updatedChats);
  };

  const handleNewChat = useCallback(() => {
    // Find the maximum existing ID
    const maxId = chats.reduce((max, chat) => Math.max(max, chat.id), -0);

    const newChat: ChatHistory = {
      // thread_id: 263,
      id: maxId + 1,
      title: `Chat ${maxId + 1}`,
      messages: [
        // {
        //   id: 0,
        //   text: "Hello! How can I assist you today?",
        //   role: "assistant",
        // },
      ],
    };
    setChats((prevChats) => [...prevChats, newChat]);
    setSelectedChatId(newChat.id);
  }, [chats.length]);

  const [isDeletingChats, setIsDeletingChats] = useState(false);
  const hasCreatedInitialChat = useRef(false);
  //automatic add a chat if chat array is empty
  // useEffect(() => {
  //   // Check if chats are empty and if initial chat has not been created
  //   if (chats.length === 0 && !hasCreatedInitialChat.current) {
  //     handleNewChat();
  //     hasCreatedInitialChat.current = true;
  //   }
  // }, [isDeletingChats]);

  const handleSelectChat = (chatId: number) => {
    setSelectedChatId(chatId);
  };

  // const handleSettingsChange = (newSettings: any) => {
  //   setSettings(newSettings);
  // };

  const deleteChat = async (chatId: number) => {
    setIsDeletingChats(true);

    const threadChatId = await fetchChatThreadID(chatId, user_id);
    console.log("chatThreadIDchatThreadID", threadChatId);

    if (threadChatId) {
      const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
      console.log("urlEnv", urlEnv);
      try {
        const response = await axios.post(`${urlEnv}delete-chat-db`, {
          chatId: chatId,
          chatThreadID: threadChatId,
          user_id: user_id,
        });
        if (response) {
          console.log(response.data);
        } else {
          console.log("chat from DB deleted");
        }
      } catch (error) {
        console.log(error);
      }
    }

    const updatedChats = chats.filter((chat) => chat.id !== chatId);
    setChats(updatedChats);
    console.log("chatIdchatIdchatId", chatId);
    if (selectedChatId === chatId) {
      setSelectedChatId(
        updatedChats.length > 0
          ? updatedChats[updatedChats.length - 1].id
          : null
      );
    }

    setIsDeletingChats(false);
  };

  const fetchChatThreadID = async (chat_id, user_id) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "getThreadId";

    const response = await axios.get(urlEnv, {
      params: {
        chat_id: chat_id,
        user_id: user_id,
      },
    });

    console.log(response.data.thread_id);

    if (response.data) {
      return response.data.thread_id;
    } else if (!response.data) {
      return null || undefined;
    }
  };

  const [systemInstructions, setSystemInstructions] = useState<string>(() => {
    const savedSystemInstructions =
      sessionStorage.getItem("systemInstructions");
    return savedSystemInstructions || "";
  });
  const [temperature, setTemperature] = useState<number>(() => {
    const savedTemperature = sessionStorage.getItem("temperature");
    return savedTemperature !== null ? parseInt(savedTemperature, 10) : 25;
  });
  const [maxTokens, setMaxTokens] = useState<number>(() => {
    const savedMaxTokens = sessionStorage.getItem("maxTokens");
    return savedMaxTokens !== null ? parseInt(savedMaxTokens, 10) : 12;
  });

  const [topP, setTopP] = useState<number>(() => {
    const savedTopP = sessionStorage.getItem("topP");
    return savedTopP !== null ? parseFloat(savedTopP) : 1;
  });

  const [stopSequences, setStopSequences] = useState<string>(() => {
    const savedStopSequences = sessionStorage.getItem("stopSequences");
    return savedStopSequences || "";
  });
  const [frequencyPenalty, setFrequencyPenalty] = useState<number>(() => {
    const savedFrequencyPenalty = sessionStorage.getItem("frequencyPenalty");
    return savedFrequencyPenalty !== null
      ? parseFloat(savedFrequencyPenalty)
      : 0;
  });

  const [presencePenalty, setPresencePenalty] = useState<number>(() => {
    const savedPresencePenalty = sessionStorage.getItem("presencePenalty");
    return savedPresencePenalty !== null ? parseFloat(savedPresencePenalty) : 0;
  });

  useEffect(() => {
    sessionStorage.setItem("systemInstructions", systemInstructions);
  }, [systemInstructions]);

  useEffect(() => {
    sessionStorage.setItem("temperature", temperature.toString());
  }, [temperature]);

  useEffect(() => {
    sessionStorage.setItem("maxTokens", maxTokens.toString());
  }, [maxTokens]);

  useEffect(() => {
    sessionStorage.setItem("topP", topP.toString());
  }, [topP]);

  useEffect(() => {
    sessionStorage.setItem("frequencyPenalty", frequencyPenalty.toString());
  }, [frequencyPenalty]);

  useEffect(() => {
    sessionStorage.setItem("presencePenalty", presencePenalty.toString());
  }, [presencePenalty]);

  const [hasSentInitialMessage, setHasSentInitialMessage] = useState(() => {
    return sessionStorage.getItem("hasSentInitialMessage") === "true";
  });
  const [isChatLoaded, setIsChatLoaded] = useState(false);
  const sendMessageButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (
      !hasSentInitialMessage &&
      fromWelcomeScreen &&
      isChatLoaded &&
      sendMessageButtonRef.current
    ) {
      sendMessageButtonRef.current.click();

      sessionStorage.setItem("hasSentInitialMessage", "true");
    }
  }, [fromWelcomeScreen, isChatLoaded]);

  return (
    <Root>
      <CssBaseline />
      <TopAppBar position="fixed">
        <NavigationBar />
        {/* Include NavigationBar inside the top app bar */}
      </TopAppBar>
      {/* menu button */}
      <IconContainer1 leftOpen={leftOpen}>
        {!leftOpen ? (
          <IconButton
            color="inherit"
            aria-label="open left drawer"
            onClick={handleLeftDrawerToggle}
          >
            {!leftOpen ? <MenuIcon /> : ""}
            {/*<MenuIcon />*/}
          </IconButton>
        ) : (
          ""
        )}{" "}
      </IconContainer1>
      {/* seting button */}
      <IconContainer2 rightOpen={rightOpen}>
        {!rightOpen ? (
          <IconButton
            color="inherit"
            aria-label="open right drawer"
            onClick={handleRightDrawerToggle}
          >
            {!rightOpen ? <SettingsIcon /> : ""}
            {/*<SettingsIcon />*/}
          </IconButton>
        ) : (
          ""
        )}
      </IconContainer2>

      <ContentContainer leftOpen={leftOpen} rightOpen={rightOpen}>
        <SideDrawer variant="persistent" anchor="left" open={leftOpen}>
          <CloseButton
            handler={handleLeftDrawerToggle}
            rightORleft={"close-left"}
          />
          <Sidebar
            chats={chats}
            activeChatId={selectedChatId}
            startNewChat={handleNewChat}
            loadChat={handleSelectChat}
            deleteChat={deleteChat}
          />
        </SideDrawer>

        {/* message content */}
        <MainContent leftOpen={leftOpen} rightOpen={rightOpen}>
          <Container style={{ height: "100%", width: "900px" }}>
            <ChatComponent
              selectedChatId={selectedChatId}
              chats={chats}
              updateChatMessages={updateChatMessages}
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}
              sendMessageButtonRef={sendMessageButtonRef}
              onChatLoaded={() => setIsChatLoaded(true)}
            />
          </Container>
        </MainContent>

        <SideDrawer variant="persistent" anchor="right" open={rightOpen}>
          <CloseButton
            handler={handleRightDrawerToggle}
            rightORleft={"close-right"}
          />
          <SettingsPanel
            // onSettingsChange={handleSettingsChange}
            systemInstructions={systemInstructions}
            setSystemInstructions={setSystemInstructions}
            temperature={temperature}
            setTemperature={setTemperature}
            maxTokens={maxTokens}
            setMaxTokens={setMaxTokens}
            topP={topP}
            setTopP={setTopP}
            stopSequences={stopSequences}
            setStopSequences={setStopSequences}
            frequencyPenalty={frequencyPenalty}
            setFrequencyPenalty={setFrequencyPenalty}
            presencePenalty={presencePenalty}
            setPresencePenalty={setPresencePenalty}
          />
        </SideDrawer>
      </ContentContainer>
    </Root>
  );
};

export default ChatScreenWindow;
export {
  ContentContainer,
  IconContainer1,
  SideDrawer,
  IconContainer2,
  Root,
  TopAppBar,
  MainContent,
};
