// src/screens/MainScreen.tsx
import React, { useCallback, useEffect, useRef, useState } from "react";
import NavigationBar from "./Navigationbar";
import WelcomeSection from "./WelcomeSection";
import SuggestedTopics from "./SuggestedTopics";
import AppFooter from "./Footer";
import "src/styles.css";
import { styled } from "@mui/system";
import { Container, CssBaseline, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  CloseButton,
  IconContainer1,
  IconContainer2,
  MainContent,
  Root,
  SideDrawer,
  TopAppBar,
} from "../components/ChatScreenWindow";
import Sidebar from "../components/SideBar";
import { ChatHistory } from "../components/SendMessage";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../components/AuthContext";
import SettingsPanel from "../components/SettingsPanel";

const ContentContainer = styled("div")<{
  leftOpen: boolean;
  rightOpen: boolean;
}>(({ leftOpen, rightOpen }) => ({
  // display: "flex",
  // flexGrow: 1,
  margin: "70px 20px 0 20px", // Adjust based on the height of both app bars
  transition:
    "margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin-right 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",

  backgroundColor: "#1f1f1f",
}));

const WelcomeScreen: React.FC = () => {
  const { session } = useAuth();
  const user_id = session?.user?.user_metadata.user_id;
  const [leftOpen, setLeftOpen] = React.useState(false);
  const [rightOpen, setRightOpen] = React.useState(false);
  const [isDeletingChats, setIsDeletingChats] = useState(false);

  const [chats, setChats] = useState<ChatHistory[]>(() => {
    const savedChats = sessionStorage.getItem("chats");
    return savedChats ? JSON.parse(savedChats) : [];
  });
  useEffect(() => {
    sessionStorage.setItem("chats", JSON.stringify(chats));
  }, [chats]);

  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const initialLoad = useRef(true);
  const location = useLocation();
  const fromWelcomeScreen = location.state?.fromWelcomeScreen;
  useEffect(() => {
    const chatExists = chats.length > 0;
    const chatInitialized =
      sessionStorage.getItem("chatInitialized") === "true";
    if (
      !fromWelcomeScreen &&
      initialLoad.current &&
      !chatExists &&
      !chatInitialized
    ) {
      initialLoad.current = false;
      // Find the maximum existing ID
      const maxId = chats.reduce((max, chat) => Math.max(max, chat.id), -0);
      const newChat: ChatHistory = {
        // thread_id: 263,
        id: maxId + 1,
        title: `Chat ${maxId + 1}`,
        messages: [
          // {
          //   id: 0,
          //   text: "Hello! How can I assist you today?",
          //   role: "assistant",
          // },
        ],
      };
      setChats((prevChats) => [...prevChats, newChat]);
      setSelectedChatId(newChat.id);
      sessionStorage.setItem("chatInitialized", "true");
    }
  }, []);

  const handleLeftDrawerToggle = () => {
    setLeftOpen(!leftOpen);
  };

  const handleRightDrawerToggle = () => {
    setRightOpen(!rightOpen);
  };

  const handleNewChat = useCallback(() => {
    // Find the maximum existing ID
    const maxId = chats.reduce((max, chat) => Math.max(max, chat.id), -0);

    const newChat: ChatHistory = {
      // thread_id: 263,
      id: maxId + 1,
      title: `Chat ${maxId + 1}`,
      messages: [
        // {
        //   id: 0,
        //   text: "Hello! How can I assist you today?",
        //   role: "assistant",
        // },
      ],
    };
    setChats((prevChats) => [...prevChats, newChat]);
    setSelectedChatId(newChat.id);
  }, [chats.length]);

  const deleteChat = async (chatId: number) => {
    setIsDeletingChats(true);

    const threadChatId = await fetchChatThreadID(chatId, user_id);
    console.log("chatThreadIDchatThreadID", threadChatId);

    if (threadChatId) {
      const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
      console.log("urlEnv", urlEnv);
      try {
        const response = await axios.post(`${urlEnv}delete-chat-db`, {
          chatId: chatId,
          chatThreadID: threadChatId,
          user_id: user_id,
        });
        if (response) {
          console.log(response.data);
        } else {
          console.log("chat from DB deleted");
        }
      } catch (error) {
        console.log(error);
      }
    }

    const updatedChats = chats.filter((chat) => chat.id !== chatId);
    setChats(updatedChats);
    console.log("chatIdchatIdchatId", chatId);
    if (selectedChatId === chatId) {
      setSelectedChatId(
        updatedChats.length > 0
          ? updatedChats[updatedChats.length - 1].id
          : null
      );
    }

    setIsDeletingChats(false);
  };
  const fetchChatThreadID = async (chat_id, user_id) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "getThreadId";

    const response = await axios.get(urlEnv, {
      params: {
        chat_id: chat_id,
        user_id: user_id,
      },
    });

    console.log(response.data.thread_id);

    if (response.data) {
      return response.data.thread_id;
    } else if (!response.data) {
      return null || undefined;
    }
  };

  const handleSelectChat = (chatId: number) => {
    setSelectedChatId(chatId);
  };

  const [systemInstructions, setSystemInstructions] = useState<string>(() => {
    const savedSystemInstructions =
      sessionStorage.getItem("systemInstructions");
    return savedSystemInstructions || "";
  });
  const [temperature, setTemperature] = useState<number>(() => {
    const savedTemperature = sessionStorage.getItem("temperature");
    return savedTemperature !== null ? parseInt(savedTemperature, 10) : 25;
  });
  const [maxTokens, setMaxTokens] = useState<number>(() => {
    const savedMaxTokens = sessionStorage.getItem("maxTokens");
    return savedMaxTokens !== null ? parseInt(savedMaxTokens, 10) : 12;
  });

  const [topP, setTopP] = useState<number>(() => {
    const savedTopP = sessionStorage.getItem("topP");
    return savedTopP !== null ? parseFloat(savedTopP) : 1;
  });

  const [stopSequences, setStopSequences] = useState<string>(() => {
    const savedStopSequences = sessionStorage.getItem("stopSequences");
    return savedStopSequences || "";
  });
  const [frequencyPenalty, setFrequencyPenalty] = useState<number>(() => {
    const savedFrequencyPenalty = sessionStorage.getItem("frequencyPenalty");
    return savedFrequencyPenalty !== null
      ? parseFloat(savedFrequencyPenalty)
      : 0;
  });

  const [presencePenalty, setPresencePenalty] = useState<number>(() => {
    const savedPresencePenalty = sessionStorage.getItem("presencePenalty");
    return savedPresencePenalty !== null ? parseFloat(savedPresencePenalty) : 0;
  });

  useEffect(() => {
    sessionStorage.setItem("systemInstructions", systemInstructions);
  }, [systemInstructions]);

  useEffect(() => {
    sessionStorage.setItem("temperature", temperature.toString());
  }, [temperature]);

  useEffect(() => {
    sessionStorage.setItem("maxTokens", maxTokens.toString());
  }, [maxTokens]);

  useEffect(() => {
    sessionStorage.setItem("topP", topP.toString());
  }, [topP]);

  useEffect(() => {
    sessionStorage.setItem("frequencyPenalty", frequencyPenalty.toString());
  }, [frequencyPenalty]);

  useEffect(() => {
    sessionStorage.setItem("presencePenalty", presencePenalty.toString());
  }, [presencePenalty]);

  const [hasSentInitialMessage, setHasSentInitialMessage] = useState(() => {
    return sessionStorage.getItem("hasSentInitialMessage") === "true";
  });
  const [isChatLoaded, setIsChatLoaded] = useState(false);
  const sendMessageButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (
      !hasSentInitialMessage &&
      fromWelcomeScreen &&
      isChatLoaded &&
      sendMessageButtonRef.current
    ) {
      sendMessageButtonRef.current.click();

      sessionStorage.setItem("hasSentInitialMessage", "true");
    }
  }, [fromWelcomeScreen, isChatLoaded]);
  return (
    <>
      <Root>
        <CssBaseline />
        <TopAppBar position="fixed">
          <NavigationBar />
          {/* Include NavigationBar inside the top app bar */}
        </TopAppBar>
        <IconContainer1 leftOpen={leftOpen}>
          {!leftOpen ? (
            <IconButton
              color="inherit"
              aria-label="open left drawer"
              onClick={handleLeftDrawerToggle}
            >
              {!leftOpen ? <MenuIcon /> : ""}
              {/*<MenuIcon />*/}
            </IconButton>
          ) : (
            ""
          )}{" "}
        </IconContainer1>

        <IconContainer2 rightOpen={rightOpen}>
          {!rightOpen ? (
            <IconButton
              color="inherit"
              aria-label="open right drawer"
              onClick={handleRightDrawerToggle}
            >
              {!rightOpen ? <SettingsIcon /> : ""}
              {/*<SettingsIcon />*/}
            </IconButton>
          ) : (
            ""
          )}
        </IconContainer2>

        <ContentContainer leftOpen={leftOpen} rightOpen={rightOpen}>
          <SideDrawer variant="persistent" anchor="left" open={leftOpen}>
            <CloseButton
              handler={handleLeftDrawerToggle}
              rightORleft={"close-left"}
            />
            <Sidebar
              chats={chats}
              activeChatId={selectedChatId}
              startNewChat={handleNewChat}
              loadChat={handleSelectChat}
              deleteChat={deleteChat}
            />
          </SideDrawer>

          <MainContent leftOpen={leftOpen} rightOpen={rightOpen}>
            <WelcomeSection />
          </MainContent>

          <SideDrawer variant="persistent" anchor="right" open={rightOpen}>
            <CloseButton
              handler={handleRightDrawerToggle}
              rightORleft={"close-right"}
            />
            <SettingsPanel
              // onSettingsChange={handleSettingsChange}
              systemInstructions={systemInstructions}
              setSystemInstructions={setSystemInstructions}
              temperature={temperature}
              setTemperature={setTemperature}
              maxTokens={maxTokens}
              setMaxTokens={setMaxTokens}
              topP={topP}
              setTopP={setTopP}
              stopSequences={stopSequences}
              setStopSequences={setStopSequences}
              frequencyPenalty={frequencyPenalty}
              setFrequencyPenalty={setFrequencyPenalty}
              presencePenalty={presencePenalty}
              setPresencePenalty={setPresencePenalty}
            />
          </SideDrawer>
        </ContentContainer>
        <AppFooter />
      </Root>
    </>
  );
};

export default WelcomeScreen;
