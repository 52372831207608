import { createClient } from "@supabase/supabase-js";

const options = {
  db: {
    schema: "public",
  },
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
  },
  global: {
    headers: { "x-my-custom-header": "PanelsAI" },
  },
};
export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_PROJ_ID,
  process.env.REACT_APP_SUPABASE_API_KEY,
  options
);
