import React from "react";
import "../styles.css";
import cubes from "../assets/cubes-solid.svg";
import film from "src/assets/film-solid.svg";
import bulb from "src/assets/lightbulb-regular.svg";
import map from "src/assets/map-solid.svg";
import mug from "src/assets/mug-hot-solid.svg";
import pentosquare from "src/assets/pen-to-square-regular.svg";
import pencil from "src/assets/pencil-solid.svg";
interface Topic {
  text: string;
  icon: string;
}

const topics1: Topic[] = [
  { text: "Write a story in my favorite genre", icon: pencil },
  { text: "List of good 80's films", icon: film },
  { text: "Tips to overcome procrastination", icon: bulb },
  { text: "Explain Pareto Principle", icon: cubes },
  { text: "Give me a good coffee recipe", icon: mug },
  { text: "Create a workout plan", icon: pentosquare },
  { text: "Activities to make friends in a new city", icon: map },
];

const SuggestedTopics: React.FC = () => {
  return (
    <>
      <div className="topic-label">
        <span>Suggested Topics</span>
      </div>

      <div className="suggest-topic">
        <div className="suggested-topics">
          {topics1.map((topic, index) => (
            <div key={index} className="card">
              <img
                src={topic.icon}
                alt="icon"
                style={{ width: "13px", height: "13px", marginRight: "8px" }}
              />
              <div className="text">{topic.text}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SuggestedTopics;
